/* eslint max-len: 0 */

export default {
  local: {
    frontendHost: 'http://local.andromeda-lc.com:3000',
    label: 'local',
    optimizelySdk: '2N6MFQAt86JJ2mvviVRArg',
    requestQuoteUrl:
      'https://lead-generation-service.preprod.aze.shell.io/request-a-quote',
    salesforceUrl: 'http://localhost:5000/salesforce',
    segmentKey: 'YFy8XN6LJY3TEQz0jvmB707hERnMd1fV',
    segmentKeyDF: 'YFy8XN6LJY3TEQz0jvmB707hERnMd1fV',
    sendPerformanceMetrics: false,
    sentryKey: 'https://14e8a1786dab467f9e82f060a30c727b@sentry.io/198341',
    webChatUrl: 'http://localhost:5000/webchat',
  },
  test: {
    frontendHost: 'http://local.andromeda-lc.com:3000',
    label: 'test',
    optimizelySdk: '2N6MFQAt86JJ2mvviVRArg',
    requestQuoteUrl:
      'https://lead-generation-service.preprod.aze.shell.io/request-a-quote',
    salesforceUrl: 'https://lubechat-server.preprod.aze.shell.io/salesforce',
    segmentKey: 'YFy8XN6LJY3TEQz0jvmB707hERnMd1fV',
    segmentKeyDF: 'YFy8XN6LJY3TEQz0jvmB707hERnMd1fV',
    sendPerformanceMetrics: false,
    sentryKey: 'https://14e8a1786dab467f9e82f060a30c727b@sentry.io/198341',
    webChatUrl: 'https://lubechat-server.preprod.aze.shell.io/webchat',
  },
  development: {
    frontendHost: 'https://dev.andromeda-lc.com',
    label: 'development',
    optimizelySdk: '2N6MFQAt86JJ2mvviVRArg',
    requestQuoteUrl:
      'https://lead-generation-service.preprod.aze.shell.io/request-a-quote',
    salesforceUrl: 'https://lubechat-server.preprod.aze.shell.io/salesforce',
    segmentKey: 'kj7YaC04bFJF4cUI4yaK1LcPvA1L2vpz',
    segmentKeyDF: 'kj7YaC04bFJF4cUI4yaK1LcPvA1L2vpz',
    sendPerformanceMetrics: false,
    sentryKey: 'https://14e8a1786dab467f9e82f060a30c727b@sentry.io/198341',
    webChatUrl: 'https://lubechat-server.preprod.aze.shell.io/webchat',
  },
  production: {
    frontendHost: 'https://andromeda-lc.com',
    label: 'production',
    optimizelySdk: '7vF19X6cnWP44s1zitwNvJ',
    requestQuoteUrl:
      'https://lead-generation-service.prod.aze.shell.io/request-a-quote',
    salesforceUrl: 'https://lubechat-server.prod.aze.shell.io/salesforce',
    segmentKey: 'iU3Dj8BwBq6HQxdEt4xbUQiJTJeW1Ywx',
    segmentKeyDF: 'FFhNfwsIBFQMJrXJte1IdPNj97NSPmrB',
    sendPerformanceMetrics: true,
    sentryKey: 'https://14e8a1786dab467f9e82f060a30c727b@sentry.io/198341',
    webChatUrl: 'https://lubechat-server.prod.aze.shell.io/webchat',
  },
}[process.env.APP_TARGET_ENV];
