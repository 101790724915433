/* eslint-disable*/

var envConfig = require('../app/config/envs');

try {
  var uri =
    envConfig.default.frontendHost +
    '/js/index.bundle.js?invalidate=1508423374443';
  checkCDNStatus(uri, function () {
    if (this.readyState !== XMLHttpRequest.DONE) return;

    if (this.status >= 300 && this.status <= 599) {
      throw new Error('cannot reach lubechat cdn');
    }

    injectAttachmentPoint();
    injectScript(uri);
  });
} catch (err) {
  // log the error somewhere
}

function checkCDNStatus(path, cb) {
  var req = new XMLHttpRequest();
  req.open('GET', path, true);
  req.timeout = 5000;
  req.onreadystatechange = cb;
  req.send();
}

function injectAttachmentPoint() {
  var el = document.createElement('div');
  el.id = 'lc-app-container';
  document.body.appendChild(el);
}

function injectScript(uri) {
  var el = document.createElement('script');
  el.type = 'text/javascript';
  el.async = 'async';
  el.src = uri;
  el.crossorigin = 'anonymous';
  document.body.appendChild(el);
}
